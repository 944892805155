import './utility/setLogin';
import app from './config/configApp';
import appAxios from '@/utility/appAxios';
import restMethods from '@/utility/restMethods';
import router from './routes/protectedRoute';
import store from '@/vuex/store';
import './static/css/style.css';
// Vue 3rd party plugins
import '@/core/plugins/ant-design';
import '@/core/plugins/fonts';
import '@/core/plugins/maps';
import '@/core/plugins/masonry';
import '@/core/plugins/apexcharts';
import '@/core/components/custom';
import '@/core/components/style';
import {endpoints} from '@/service/api';

app.config.productionTip = false;
app.config.globalProperties.$restMethods = restMethods;
app.config.globalProperties.$appAxios = appAxios;
app.config.globalProperties.$endpoints = endpoints;
app.use(store);
app.use(router);
app.mount('#app');
