import axios from 'axios'
import { message } from 'ant-design-vue'

const appAxios = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
})

appAxios.interceptors.request.use(config => {
  const token = localStorage.getItem('jwtToken')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

class ApiResponse {
  constructor(type, data) {
    this.type = type
    this.data = data
  }

  isUnauthorized() {
    return this.type === 'UNAUTHORIZED'
  }

  hasError() {
    return this.type !== 'SUCCESS'
  }

  getDataMessage() {
    return this.data.message
  }

  getData() {
    return this.data
  }
}

const post = (endpoint, data = {}, config = {}) => {
  if (typeof config.show_error === 'undefined') {
    config.show_error = true
  }

  if (typeof config.show_success === 'undefined') {
    config.show_success = false
  }

  return appAxios
    .post(endpoint, data)
    .then(response => {
      return new ApiResponse(response.data.type, response.data.data)
    })
    .catch(function(error) {
      console.log(error.config)

      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)

        return new ApiResponse(
          'ERROR',
          'Sunucu tarafından 200 haricinde bir cevap geldi. Sorunun devamı durumunda bize ulaşınız.',
        )
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request)

        return new ApiResponse('ERROR', 'Sunucu tarafından cevap alınamadı. Sorunun devamı durumunda bize ulaşınız.')
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message)

        return new ApiResponse('ERROR', 'Bilinmeyen bir hata meydana geldi. Sorunun devamı durumunda bize ulaşınız.')
      }
    })
    .then(apiResponse => {

      if (apiResponse.isUnauthorized()) {
        window.location.href = '/auth/login'
      }

      if (config.show_error) {
        if (apiResponse.hasError()) {
          message.error(apiResponse.getDataMessage())
        }
      }

      if (config.show_success) {
        if ( ! apiResponse.hasError()) {
          message.success(apiResponse.getDataMessage())
        }
      }

      return apiResponse
    })
}

export default {
  /**
   * @param {string} endpoint
   * @param {object} data
   * @param {{show_success?: boolean, show_error?: boolean}} config
   *
   * @return {Promise<ApiResponse>}
   */
  postForNoc: (endpoint, data = {}, config = {}) => post('/admin' + endpoint, data, config),
}
