import { createRouter, createWebHistory } from 'vue-router'
import adminRoutes from './AdminRoutes'
import authRoutes from './authRoutes'

const routes = [
  {
    name: 'Admin',
    path: '/',
    component: () => import( '@/layout/withAdminLayout.vue'),
    children: [...adminRoutes],
  },
  {
    name: 'Auth',
    path: '/auth',
    component: () => import( '@/layout/withAuthLayout.vue'),
    children: [...authRoutes],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkExactActiveClass: 'active',
  routes,
})

export default router
