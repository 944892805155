export default [
  {
    path: '/',
    name: 'Index.Page',
    component: () => import( '@/view/pages/MainPage.vue'),
  },
  {
    path: 'user-groups',
    name: 'UserGroups.Page',
    component: () => import( '@/view/pages/UserGroups.vue'),
  },
  {
    path: 'user-groups-match',
    name: 'UserGroupsMatch.Page',
    component: () => import( '@/view/pages/UserGroupMatch.vue'),
  },
  {
    path: 'contracted-cargo',
    name: 'ContractedCargo.Page',
    component: () => import( '@/view/pages/ContractedCargo.vue'),
  },
  {
    path: 'customer-movements-detail',
    name: 'CustomerMovementsDetail.Page',
    component: () => import( '@/view/pages/CustomerMovementsDetail.vue'),
  },
  {
    path: 'incoming-invoices/list',
    name: 'IncomingInvoices.List.Page',
    component: () => import( '@/view/pages/IncomingInvoices.vue'),
  },
  {
    path: 'incoming-invoices/detail',
    name: 'IncomingInvoices.Detail.Page',
    component: () => import( '@/view/pages/ReceiptDetail.vue'),
  },
  {
    path: 'customer-selected-detail/:invoice_id/:customer_id',
    name: 'CustomerSelectedDetail.Page',
    component: () => import( '@/view/pages/CustomerSelectedDetail.vue'),
  },
  {
    path: 'redirect',
    name: 'redirect',
    component: () => import( '@/view/pages/Redirect.vue'),
  },
  {
    path: 'consensus',
    name: 'Consensus.Page',
    component: () => import( '@/view/pages/Consensus.vue'),
  },
  {
    path: 'cargong-payment-list',
    name: 'CargongPaymentList.Page',
    component: () => import( '@/view/pages/CargongPaymentList.vue'),
  },
  {
    path: 'tsoft-kargo-payment-list',
    name: 'TsoftKargoPaymentList.Page',
    component: () => import( '@/view/pages/TsoftKargoPaymentList.vue'),
  },
  {
    path: 'cargong-add-credit',
    name: 'CargongAddCredit.Page',
    component: () => import( '@/view/cargong/AddCredit.vue'),
  },
  {
    path: 'tsoft-kargo-add-credit',
    name: 'TsoftKargoAddCredit.Page',
    component: () => import( '@/view/tsoft-kargo/AddCredit.vue'),
  },
  {
    path: 'cargong-account-list',
    name: 'CargongAccountList.Page',
    component: () => import( '@/view/cargong/AccountList.vue'),
  },
  {
    path: 'cargong-shipment-list',
    name: 'CargongShipmentList.Page',
    component: () => import( '@/view/cargong/ShipmentList.vue'),
  },
  {
    path: 'tsoft-kargo-account-list',
    name: 'TsoftKargoAccountList.Page',
    component: () => import( '@/view/tsoft-kargo/AccountList.vue'),
  },
  {
    path: 'tsoft-kargo-shipment-list',
    name: 'TsoftKargoShipmentList.Page',
    component: () => import( '@/view/tsoft-kargo/ShipmentList.vue'),
  },
  {
    path: 'admin-list',
    name: 'AdminList.Page',
    component: () => import( '@/view/pages/AdminList.vue'),
  },
]
