const routes = [
  {
    path: "login",
    name: "login",
    component: () => import("@/view/authentication/SignIn.vue")
  },
  {
    path: "register",
    name: "register",
    component: () => import("@/view/authentication/Signup.vue")
  },
  {
    path: "forgotPassword",
    name: "forgotPassword",
    component: () => import("@/view/authentication/ForgotPassword.vue")
  },
  {
    path: "setLogin/:token",
    name: "setLogin",
    component: () => import("@/view/authentication/SetLogin.vue")
  }
];

export default routes;
