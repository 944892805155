import mutations from './mutations';
// import Cookies from 'js-cookie';

const state = () => ({
    // role:localStorage.getItem('role'),
    // login: localStorage.getItem('logedIn'),
    jwtAuth:localStorage.getItem('jwtAuth'),
    jwtToken: localStorage.getItem('jwtToken')
});

const actions = {
    async login({ commit },authData) {
        commit('setToken',authData.token);
        // commit('setRole',authData.role);
        localStorage.setItem('jwtToken', authData.token);
        // localStorage.setItem('logedIn', true);
        // localStorage.setItem('role', authData.role);
    },
    async logOut({ commit }) {
        commit('setToken','');
        localStorage.removeItem('jwtToken');
        commit('setLogin',false);
        // localStorage.removeItem('logedIn');
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
