import axios from 'axios';
import store from '@/vuex/store';
import router from '../routes/protectedRoute';

const appAxios = axios.create({ baseURL: process.env.VUE_APP_API_URL, });

appAxios.interceptors.request.use((config) => {
    const token = store.state.auth.jwtToken;
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

appAxios.interceptors.response.use(
    response => response,
    error => {
        console.log("utility--");
        console.log(error.response);
        if (error.response.status === 401) {
            store.dispatch('auth/logOut');
            router.push('/auth/login');
        } else if (error.response.status === 403){

        }
    }
);
export default appAxios;

